.teacher {
    border: 1px solid #e9ecef;
    padding: 30px;
    margin-bottom: 5rem;
}
.teacher img {
    position: relative;
    margin-top: -100px;
}
.teacher h3 {
    font-size: 1.2rem;
}
.teacher .position {
    color: #adb5bd;
}