/* Base */
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: bold;
	src: url('WebFonts/fonts/eot/IRANSansWeb_Bold.eot');
	src: url('WebFonts/fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('WebFonts/fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('WebFonts/fonts/woff/IRANSansWeb_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('WebFonts/fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 500;
	src: url('WebFonts/fonts/eot/IRANSansWeb_Medium.eot');
	src: url('WebFonts/fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('WebFonts/fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('WebFonts/fonts/woff/IRANSansWeb_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('WebFonts/fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 300;
	src: url('WebFonts/fonts/eot/IRANSansWeb_Light.eot');
	src: url('WebFonts/fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('WebFonts/fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('WebFonts/fonts/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('WebFonts/fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 200;
	src: url('WebFonts/fonts/eot/IRANSansWeb_UltraLight.eot');
	src: url('WebFonts/fonts/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('WebFonts/fonts/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('WebFonts/fonts/woff/IRANSansWeb_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('WebFonts/fonts/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
	src: url('WebFonts/fonts/eot/IRANSansWeb.eot');
	src: url('WebFonts/fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('WebFonts/fonts/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('WebFonts/fonts/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('WebFonts/fonts/ttf/IRANSansWeb.ttf') format('truetype');
}

body {
    line-height: 1.7;
    background-color: #000000;
    font-weight: 300;
    font-size: 1.1rem;
    font-family: IRANSans;
    font-weight: 300;
}

.mt-6 {
    margin-top: 6rem !important;
}

.persian-text {
    direction: rtl;
    text-align: right;
}

.persian-text-center {
	direction: rtl;
    text-align: center;
}

::-moz-selection {
    background: #fff;
    color: #000;
}
::selection {
    background: #fff;
    color: #000;
}

.a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.a:hover {
    text-decoration: none;
}

.text-black {
    color: #F9F9F9 !important;
}
p {
    color: #F9F9F9 !important;
}
.bg-light {
    background-color: #F8F9FA !important;
}

.btn {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    padding: 14px 30px;
}
.btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.btn-primary {
    color: #000 !important;
    background-color: #2bbbad !important;
    border-color: #2bbbad !important;
}
.btn.btn-pill {
    border-radius: 30px;
}

.form-control {
    height: 48px;
}
.form-control:active, .form-control:focus {
    border-color: #2bbbad;
}
.form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}