/* Navbar */
.site-logo {
    position: relative;
    font-weight: 900;
    font-size: 1.3rem;
}
.site-logo a {
    color: #fff;
}

.site-navbar {
    margin-bottom: 0px;
    z-index: 1999;
    position: absolute;
    width: 100%;
}
.site-navbar .container-fluid {
    padding-left: 7rem;
    padding-right: 7rem;
}
@media (max-width: 1100px) {
    .site-navbar .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid #f3f3f4 !important;
}
.site-navbar .site-navigation .site-menu {
    margin-bottom: 0;
}
.site-navbar .site-navigation .site-menu .active {
    color: #2bbbad;
    display: inline-block;
    padding: 5px 20px;
}
.site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
}
.site-navbar .site-navigation .site-menu > li {
    display: inline-block;
}
.site-navbar .site-navigation .site-menu > li > a {
padding: 5px 20px;
    color: #fff;
    display: inline-block;
    text-decoration: none !important;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
    color: #fff;
}
.site-navbar .site-navigation .site-menu.site-menu-dark > li > a {
    color: #000;
}

.site-mobile-menu {
    width: 300px;
    position: fixed;
    right: 0;
    z-index: 2000;
    padding-top: 20px;
    background: #fff;
    height: calc(100vh);
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;
}
.show .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}
.site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
    float: right;
    margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
    font-size: 30px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 1;
    cursor: pointer;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
    color: #25262a;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
    float: left;
    margin-top: 10px;
    margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
    display: inline-block;
    text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
    max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
    text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 20px 20px 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px;
}
.site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}
.site-mobile-menu .site-nav-wrap a {
    padding: 10px 20px;
    display: block;
    position: relative;
    color: #212529;
}
.site-mobile-menu .site-nav-wrap a:hover {
    color: #2bbbad;
}
.site-mobile-menu .site-nav-wrap li {
    position: relative;
    display: block;
}
.site-mobile-menu .site-nav-wrap li .active {
    color: #2bbbad;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 20;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
    background: #f8f9fa;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
    font-size: 12px;
    z-index: 20;
    font-family: "icomoon";
    content: "\f078";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    -ms-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap > li {
    display: block;
    position: relative;
    float: left;
    width: 100%;
}
.site-mobile-menu .site-nav-wrap > li > a {
    padding-left: 20px;
    font-size: 20px;
}
.site-mobile-menu .site-nav-wrap > li > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.site-mobile-menu .site-nav-wrap > li > ul > li {
    display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
    padding-left: 40px;
    font-size: 16px;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
    padding: 0;
    margin: 0;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
    display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
    font-size: 16px;
    padding-left: 60px;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] {
    float: left;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 5em;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
    width: auto;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
    padding-left: 15px !important;
}

/* Sticky Header */
.sticky-outer-wrapper {
    position: absolute;
    z-index: 100;
    width: 100%;
}
.sticky-outer-wrapper .site-navbar {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.sticky-outer-wrapper .site-navbar .site-menu > li {
    display: inline-block;
}
.sticky-outer-wrapper .site-navbar .site-menu > li > a.active {
    color: #fff;
    position: relative;
}
.sticky-outer-wrapper .site-navbar .site-menu > li > a.active:after {
    height: 2px;
    background: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
}
.sticky-outer-wrapper.is-sticky .site-navbar {
    -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
    background: #fff;
}
.sticky-outer-wrapper.is-sticky .site-navbar .site-logo a {
    color: #000;
}
.sticky-outer-wrapper.is-sticky .site-navbar .site-logo a:hover {
    color: #28aa9e;
}
.sticky-outer-wrapper.is-sticky .site-navbar .site-menu > li {
    display: inline-block;
}
.sticky-outer-wrapper.is-sticky .site-navbar .site-menu > li > a {
    padding: 5px 20px;
    color: #000;
    display: inline-block;
    text-decoration: none !important;
}
.sticky-outer-wrapper.is-sticky .site-navbar .site-menu > li > a:hover {
    color: #2bbbad;
}
.sticky-outer-wrapper.is-sticky .site-navbar .site-menu > li > a.active:after {
    background: #2bbbad;
}
.sticky-outer-wrapper .shrink {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}