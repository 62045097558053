.cta a span {
    border-color: #2bbbad;
    padding: 14px 20px;
    color: #000;
    background: #2bbbad;
    border-radius: 30px;
    font-size: .7rem;
    font-weight: 900;
    text-transform: uppercase;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.is-sticky .cta a span {
    color: #000;
}

.cta a:hover span {
    background: #28aa9e;
    color: #000;
}

.cta a.active {
    border-bottom: none;
}

/*Sections*/
.intro-section {
    position: relative;
}

.intro-section, .intro-section .container > .row {
    height: 100vh;
    min-height: 900px;
}

.intro-section.single-cover, .intro-section.single-cover .container > .row {
    height: 50vh;
    min-height: 400px;
}

.intro-section h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 1.5rem;
}

.intro-section p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.1rem;
}

.site-section {
    padding: 4em 0;
    position: relative;
    background-color: #000;
}

@media (max-width: 991.98px) {
    .site-section {
        padding: 4em 0;
    }
}

.section-title {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 1.8rem;
    font-weight: 900;
}

@media (max-width: 991.98px) {
    .section-title {
        font-size: 1.5rem;
    }
}

.slide-1 {
    background-size: cover;
    background-position: center;
    background-color: black;
}


@media (min-width: 991.98px) {
    #defaultCanvas0 {
        left: -50vw !important;
    }
}

.slide-1:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: .9;
    border-bottom-right-radius: 0px;
}

@media (max-width: 991.98px) {
    .slide-1:before {
        width: 100%;
    }
}

.slide-1 .slide-text {
    opacity: 0;
    visibility: hidden;
    margin-top: 50px;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;
}

.slide-1 .slide-text.active {
    opacity: 1;
    visibility: visible;
    margin-top: 0px;
}

.form-box {
    padding: 40px;
    background: #fff;
    border-radius: 7px;
}