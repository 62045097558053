.custom-icon-wrap .custom-icon-inner {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #7971ea;
}
.custom-icon-wrap .custom-icon-inner .icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: .8rem;
}
.custom-icon-wrap h3 {
    font-size: 1.05rem;
    color: #7971ea;
}